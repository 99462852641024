import { useEffect } from "react";
import { useDispatch } from "react-redux";

import "@styles/index.less";
import { client_routeConfig, Routes } from "@router";
import { fetchCountries, saveTokens, useVerifyMutation } from "@services";
import Layout from "@components/Layout";
import { useTypedSelector } from "@hooks";
import { clientVars, ssoUrl } from "./constants";
import {
  getTokenFromSharedStorage,
  getSelectedCompanyFromStorage,
} from "./services/cookies";
import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import "./styles.less";

declare global {
  interface Window {
    less: any;
  }
}

const App = () => {
  const dispatch = useDispatch();
  const { countries } = useTypedSelector(state => state.countries);
  const { user } = useTypedSelector(state => state.auth);
  const [verifyUser, { status }] = useVerifyMutation();

  useEffect(() => {
    if (!countries?.length && user?.id) {
      dispatch(fetchCountries());
    }
  }, [dispatch, countries, user]);

  useEffect(() => {
    if (window?.less?.modifyVars) {
      window?.less?.modifyVars(clientVars);
    }
  }, []);

  useEffect(() => {
    const ssoTokens = getTokenFromSharedStorage();
    if (ssoTokens) {
      saveTokens(ssoTokens);
      const companyId = getSelectedCompanyFromStorage();
      verifyUser({
        selected_application: process.env.REACT_APP_APPLICATION || "JE",
        companyId,
      });
    } else {
      window.open(ssoUrl, "_self");
    }
  }, [verifyUser]);

  if (status === QueryStatus.rejected) {
    window.open(ssoUrl, "_self"); // if Auth Verification failed. open SSOlogin page
  }

  return (
    <Layout>
      <Routes
        routes={
          window.location.pathname.includes("client")
            ? client_routeConfig
            : client_routeConfig
        }
      />
    </Layout>
  );
};

export default App;
